import React from "react"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import SideBySide from "../components/SideBySide"
import { graphql } from "gatsby"

const sideBySideFirstRow = [
  "up to 20 SEER",
  "sound as low as 66 dB (quiet as a vacuum)",
  "superior humidity control (180B, 187B)",
  "improved indoor air quality",
  "Two-stage operation for maximum comfort (180B, 187B)",
  "Evolution® control for ultimate performance",
  "AeroQuiet™ System II design",
  "DuraGuard™ Plus system",
  "Enhanced diagnostics with Evolution control",
  "Environmentally-sound Puron® refrigerant",
  "10-year parts limited warranty upon registration",
]
const sideBySideSecondRow = [
  {
    name: "Manage your utility costs with high-efficiency comfort:",
    description: "Model 987M - up to 98.3% AFUE Model 986T - up to 96.5% AFUE",
  },
  "Enjoy the ultra-quiet performance and even-temperature comfort of variable speed air delivery",
  "Precise gas modulation always delivers the right amount of heat for the greatest energy efficiency (987M only)",
  "Two-stage operation allows longer, more consistent heating cycles on low stage for savings and comfort (986T only)",
  "Perfect Heat™ technology means consistent comfort by adjusting system operation to changing conditions",
  "Perfect Humidity™technology removes more moisture than a standard furnace during cooling operation (when installed with a matched air conditioner or heat pump)",
  "Fan On Plus™ technology lets you choose between four speeds of continuous fan operation with a compatible control",
  "Insulated cabinet for quieter operation",
  "External filter cabinet makes filter changes easier",
  "Lifetime limited warranty on heat exchanger",
]

const sideBySideThirdRow = [
  `Enjoy cool, summer comfort up to 16 SEER & up to 12 SEER efficiency`,
  `Transition to winter with heat pump heating efficiency of up to 8.5 HSPF (677E & 677C only)`,
  `Experience gas heating efficiency up to 80.1%AFUE`,
  `Enjoy the optimized comfort of multi-speed air delivery`,
  `Two-stage operation allows longer, more consistent heating and cooling cycles on low stage for savings and comfort (677E & 577E only)`,
  `Enhanced comfort during cooling operation through reduced humidity with appropriate control`,
  `Galvanized steel cabinet with "coil on top" design for lasting durability against the elements`,
  `Louvered grille protects the coil against physical damage`,
  `Solid cabinet base panels help prevent infiltration of dirt, debris and moisture`,
  `20-year limited warranty on heat exchanger or Lifetime limited warranty on heat exchanger`,
  `10-year parts limited warranty upon registration`,
]

const sideBySideFourthRow = [
  `Full-color, touch-screen design allows photo screen saver upload`,
  `Intelligent, energy-wise programming helps you save 24-7`,
  `5-day local weather forecast`,
  `Perfect Heat® technology capable to minimize temperature fluctuations`,
  `Perfect Humidity® technology capable for optimal cooling comfort`,
  `Hybrid Heat® system management capable`,
  `Humidifier, ventilator, air purifier compatible`,
  `Four levels of fan speed control programmable by period`,
  `Intuitive, lifestyle-based programming with 7-day, wake-away-home-sleep and vacation programming`,
  `Wi-Fi® remote access capability to allow complete programming and change control from anywhere (SYSTXBBECC01-A and SYSTXVVECW01-A only)`,
  `Automatically changes over between heating and cooling`,
  `Pop-up reminders: TrueSense™ dirty air filter detection or fixed-schedule timed filter change reminders; service reminders`,
  `Zoning capable for up to 8 zones`,
  `With zoning - measures, displays and manages each zone's airflow and adjusts for quiet comfort`,
  `10-year parts limited warranty upon timely registration`,
]

export default function Bryant({ data }) {
  return (
    <Layout>
      <Seo title="Bryant" />
      <h1 className="my-4 pt-4 pb-2 text-blue font-extrabold text-3xl sm:text-4xl text-center">
        Bryant Air Conditioners and Furnaces
      </h1>
      <SideBySide
        image={data.row1Image}
        items={sideBySideFirstRow}
        imgRight
        header="Evolution System Central Air Conditioner"
        subheader="The Evolution central air conditioner delivers the ultimate in whole-home comfort with our highest SEER rating to ensure the finest in energy efficiency and economical performance"
      />
      <SideBySide
        image={data.row2Image}
        items={sideBySideSecondRow}
        header="Evolution System Variable Speed 90+% Efficiency Gas Furnaces"
        subheader="Pamper yourself with superior comfort and consistent temperature control. For the ultimate in quiet efficiency, the Model 987M warms your winters with up to 98.3% AFUE efficiency. Or, choose the Model 986T with Perfect Heat® technology and up to 96.5% AFUE. Either way, you'll experience the additional benefit of summertime humidity control through variable speed airflow."
      />
      <SideBySide
        image={data.row3Image}
        items={sideBySideThirdRow}
        imgRight
        header="Preferred Series Gas Heat/Electric Cool Systems"
        subheader="Bryant® Preferred gas/electric packaged products include our best packaged efficiency HYBRID HEAT® dual fuel systems for electric heat pump warmth and cooling combined with gas furnace heating. Or, select total climate control that pairs an air conditioner with a gas furnace for energy-saving performance and year-round, whole-home comfort."
      />
      <SideBySide
        image={data.row4Image}
        items={sideBySideFourthRow}
        header="Evolution Connex Control"
        subheader="The Evolution Connex control puts comfort control at your fingertips. Capable of managing a complete home comfort system including humidity, ventilation and zoning, it is also the brains behind Bryant's highest-efficiency Evolution products. Its user-friendly, touch-screen design is packed with intelligent, energy-management features."
      />
    </Layout>
  )
}

export const pageQuery = graphql`
  query BryantPageQuery {
    row1Image: file(relativePath: { eq: "bryant-ac.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    row2Image: file(relativePath: { eq: "BryantFurnace.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    row3Image: file(relativePath: { eq: "bryant-ac.jpg" }) {
      childImageSharp {
        gatsbyImageData
      }
    }
    row4Image: file(
      relativePath: { eq: "bryant-connex-control-white-front.png" }
    ) {
      childImageSharp {
        gatsbyImageData
      }
    }
  }
`
